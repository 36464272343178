/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";


function Contact() {
  return (
    <main id="main" className="contactus">
      <div className="breadcrumbs">
        <div
          className="page-header d-flex align-items-center"
          style={{
            backgroundImage:
              "url(" + require("../assets/slider/expertise.jpg") + ")",
          }}
        >
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 text-center ">
                <h2 className="aboutus-breadcrumb">
                  CONTACT <span className="u-text-light">US</span>
                </h2>
                {/* <div className="section-title-divider news"></div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <section id="project" className="project">
        <div className="container">
          <div className="row gy-4 mt-lg-5 mb-lg-5">
            <div className="col-lg-6 content order-last order-lg-first ">
              <div className="align-middle me-lg-5">
                <div className="section-title">
                  <h2>
                    GET IN <span className="u-text-light">TOUCH</span>
                  </h2>
                </div>
                <p className="mt-2">
                  We’re passionate about what we do and committed to our
                  clients. We believe in delivering exception results to our
                  clients and bringing positive impact to the society.{" "}
                </p>
                <div className="social-media mt-lg-5">
                  <ul>
                    <li>
                      <a href="/" className="instagram">
                        <i className="bi bi-phone"></i>
                      </a>{" "}
                      (973) 17534123
                    </li>
                    <li>
                      <a href="/" className="instagram">
                        <i className="bi bi-printer"></i>
                      </a>{" "}
                      (973) 17533340
                    </li>
                    <li>
                      <a href="mailto:aes@aesbah.com" className="instagram">
                        <i className="bi bi-envelope"></i>
                      </a>{" "}
                      aes@aesbah.com
                    </li>
                    <li>
                      <a href="/" className="instagram">
                        <i className="bi bi-clock"></i>
                      </a>{" "}
                      Sunday – Thursday: 8:00 am – 4:00 pm
                    </li>
                    <li>
                      <a href="/" className="instagram">
                        <i className="bi bi-geo-alt"></i>
                      </a>{" "}
                      Office 12, Diplomat Office Towers, Diplomatic Area,
                      Kingdom of Bahrain
                    </li>

                    {/* <li><a className="instagram"><i className="bi bi-globe"></i></a>  www.ae.com</li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 position-relative align-self-start order-lg-first order-last portfolio-item">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8511.452386080266!2d50.58810282927948!3d26.24137486375032!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e49a5f8e63aa005%3A0x2964584d2548901a!2sAnsari%20Engineering%20Services!5e0!3m2!1sen!2sbh!4v1721722478042!5m2!1sen!2sbh"
                width="100%"
                height="400px"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Contact;
