import React from "react";
import ExpertiseData from '../data/expertiseData'
import { useNavigate } from 'react-router-dom';
import { useEffect } from "react";

function Expertise() {
    const navigate = useNavigate()

    const navigateToDetail = (id) => {
        navigate(`/expertise/${id}`)
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <main id="main">
            <div className="breadcrumbs">
                <div className="page-header d-flex align-items-center" style={{ backgroundImage: "linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 1) 100%), url(" + require('../assets/expertise/banner-images/banner1.png') + ")" }}>
                    <div className="container position-relative">
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-6 text-center ">
                                <h2 className="aboutus-breadcrumb">EXPERTISE</h2>
                                {/* <div className="section-title-divider aboutus"></div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section id="breadth" className="breadth lightblackbg">
                <div className="container">
                    <div className="section-title">
                        <p className="text-center">Fragmented or complex designs, our expertise will help you bring your vision to life.</p>
                    </div>
                </div>
            </section>
            <section id="experties44" className="experties44" style={{ backgroundImage: "url(" +  require('../assets/expertise/banner-images/banner2.png') + ")" }}>
                <div className="container" >
                    <div className="row gy-4">
                        <div className="col-lg-6 content order-last order-lg-first d-flex justify-content-center align-items-center">
                            <div className="align-middle">
                                <div className="section-title">
                                    <h2>NO MATTER THE CHALLENGE,<br />WE FIND SOLUTIONS AT THE INTERSECTIONS OF <span className="u-text-light experties44-devider-">OUR EXPERTISE.</span></h2>
                                </div>
                                <p className="mt-5"> Our expertise is built on more than 44 years of developing and refining our approach to building design, always searching for unique ideas, innovative techniques and groundbreaking concepts that push the boundaries of what is possible.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 position-relative align-self-start order-lg-first order-last portfolio-item">
                            <img src={require('../assets/projects/images/project12/img2.jpg')} className="img-fluid" alt="" />
                        </div>
                    </div>

                </div>
            </section>
            <section id="experties" className="experties">
                <div className="container">

                    <div className="row gy-5 content">
                        {
                            ExpertiseData.map(item => {
                                return (
                                    <div className="col-lg-3 col-md-6 portfolio-item filter-app d-flex" onClick={() => navigateToDetail(item.id)} style={{ cursor: "pointer" }}>
                                        <div style={{display:"flex", flexDirection:"column",justifyContent:"space-between"}}>
                                            <img src={item.image} className="img-fluid" alt="" />
                                            <div className="portfolio-info" style={{height:"100%"}}>
                                                <h3 style={{textTransform:"uppercase"}} >{item.title}</h3>
                                                <p>{item.content}</p>
                                            </div>

                                        </div>

                                    </div>
                                )
                            })
                        }
                        {/* <div className="col-lg-3 col-md-6 portfolio-item filter-app">
                            <img src={require('../assets/projects/image1.jpg')} className="img-fluid" alt="" />
                            <div className="portfolio-info">
                                <h3>ARCHITECTURE</h3>
                                <p>AE's Architectural expression embodies innovation, creativity and vision, reflecting each client disticive needs.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 portfolio-item filter-app">
                            <img src={require('../assets/projects/image2.jpg')} className="img-fluid" alt="" />
                            <div className="portfolio-info">
                                <h3>CIVIL ENGINEERING</h3>
                                <p>AE's Architectural expression  embodies innovation, creativity and vision, reflecting each client disticive needs.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 portfolio-item filter-app">
                            <img src={require('../assets/projects/image3.jpg')} className="img-fluid" alt="" />
                            <div className="portfolio-info">
                                <h3>SURVEYING</h3>
                                <p>AE's Architectural expression  embodies innovation, creativity and vision, reflecting each client disticive needs.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 portfolio-item filter-app">
                            <img src={require('../assets/projects/image4.jpg')} className="img-fluid" alt="" />
                            <div className="portfolio-info">
                                <h3>OTHERS</h3>
                                <p>AE's Architectural expression  embodies innovation, creativity and vision, reflecting each client disticive needs.</p>

                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </main>
    );
}

export default Expertise;