const data = [
  {
    id:"1",
    image: require( "../assets/expertise/images/exp1.png"),
    title: "Project Management",
    projects: 
    ["19","2","3","4","5"],
    content: "We collaborate with stakeholders with a responsible, strategic, and result-driven approach to successfully complete the project on time, every time."

  },
  {
    id:"11",
    image: require( "../assets/expertise/images/exp11.png"),
    title: "CAD and Drafting",
    projects:["19","5","14","15","16"],
    content: "Our CAD drafting services—which include 2D and 3D technical drawings —are diversified and high-quality, targeting the various services."

  },
  {
    id:"12",
    image: require( "../assets/expertise/images/exp12.png"),
    title: "Site Supervision",
    projects:["7","9","6","13","16"],
    content: "We provide the latest smart home solutions suitable for residential, Commercial and Hospitality Sectors, from design through installation."

  },
  {
    id:"8",
    image: require( "../assets/expertise/images/exp8.png"),
    title: "Marine Engineering",
    projects:["5","13","14"],
    content: "From initial marine survey, through project management, tender documentation and site supervision, to final cadastral survey and land ownership certification, we do it all."

  },
  {
    id:"9",
    image: require( "../assets/expertise/images/exp9.png"),
    title: "Construction Management",
    projects:["3","10","16"],
    content: "Our specialised engineers, designers and construction managers helps you with project planning, quantity surveying, contract management, and much more."

  },
  {
    id:"6",
    image: require( "../assets/expertise/images/exp6.png"),
    title: "Cadastral and Land Surveying",
    projects:["5","13","14","15"],
    content: "Certified by the Survey Directorate of the Ministry of Works & Housing, we've one of the largest, most experienced and best equipped team in Bahrain."

  },
  {
    id:"3",
    image: require( "../assets/expertise/images/exp3.png"),
    title: "Civil and Structural Engineering",
    projects:["8","9","10","16","17"],
    content: "From concept development to construction, we apply our expertise to deliver high-performance structures that meet all applicable standards, codes, and regulations."

  },
  {
    id:"4",
    image: require( "../assets/expertise/images/exp4.png"),
    title: "Architecture and Interior Design",
    projects:["11","12","13","16"],
    content: "With a full array of architectural and interior design services, we partner with you to create high-quality, commercially astute structures."

  },
  {
    id:"5",
    image: require( "../assets/expertise/images/exp5.png"),
    title: "Master planning and Urban Design",
    projects:["19","18"],
    content: "Our urban and landscape designers, planners and architects collaborate to create cohesive master plans for urban areas, cities and regions."

  },
  
  {
    id:"2",
    image: require( "../assets/expertise/images/exp2.png"),
    title: "Mechanical and Electrical Engineering",
    projects:["12","6","7","16","17"],
    content: "Our team of senior mechanical, electrical, and plumbing engineers specializes in creating simple, efficient solutions for our clients."

  },
  {
    id:"7",
    image: require( "../assets/expertise/images/exp7.png"),
    title: "Quantity Surveying and Cost Engineering",
    projects:["5","13","14","15"],
    content: "Our experts help you predict, plan and control budget levels for your projects of every kind—residential, retail and industrial; big or small."

  },
  {
    id:"10",
    image: require( "../assets/expertise/images/exp10.png"),
    title: "Infrastructure Planning and Management",
    projects:["19","17","18"],
    content: "We help clients strengthen, improve and validate decisions throughout the feasibility, design, engineering and construction stages and ensure their objectives are met."

  }

]

export default data;