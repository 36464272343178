import React from "react";
import { useEffect } from "react";

function About() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <main id="main" className="aboutus">
            <div className="breadcrumbs">
                <div className="page-header d-flex align-items-center" style={{ backgroundImage: "url(" + require('../assets/slider/aboutus-breadcrumb-image.jpg') + ")" }}>
                    <div className="container position-relative">
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-6 text-center ">
                                <h2 className="aboutus-breadcrumb">ABOUT <span className="u-text-light">US</span></h2>
                                {/* <div className="section-title-divider aboutus"></div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section id="project" className="project">
                <div className="container">
                    <div className="section-title">
                        <h2>WHO <span className="u-text-light ">WE ARE</span></h2>
                    </div>
                    <div className="row gy-4">
                        <div className="col-lg-6 position-relative align-self-start order-lg-first order-first portfolio-item">
                            <img src={require('../assets/about/images/whoWeAre.png')} className="img-fluid" alt="" />
                            {/* <div className="portfolio-info">
                      <h4 className="text-white">MINISTRY OF HEALTH</h4>
                      <p><i className="bi bi-pin-map-fill"></i> Manama, Bahrain</p>
                  </div> */}
                        </div>
                        <div className="col-lg-6 px-lg-5 content order-last order-lg-last d-flex justify-content-center align-items-center">
                            <div className="align-middle">
                                <p>Ansari Engineering Services (AES) is a multi-disciplined Grade A Engineering Consultancy company established in 1978. We help clients achieve their vision through our expertise in architecture, engineering and construction management services.</p>
                                <p>Our technical excellence, driven by imagination, innovation, and a commitment to quality, has enabled us to deliver projects that stand the test of time. Every project we have delivered embodies the essence of design, engineering and execution.</p>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
            <section id="experties44" className="experties44" style={{ backgroundImage: "url(" + require("../assets/about/images/44yearsExperience.png") + ")" }}>
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-6 content order-last order-lg-first d-flex justify-content-center align-items-center">
                            <div className="align-middle">
                                <div className="section-title">
                                    <h2>OVER 44 YEARS OF <br /><span className="u-text-light">EXPERTISE</span></h2>
                                </div>
                                <p className="mt-5">Our team of architects, designers, and engineers has been creating beautiful spaces for more than 44 years. In these years, we have completed notable projects for industrial, retail, sports and recreation, hotel & leisure, residential, and commercial industries. We're proud to say that many iconic buildings in Bahrain have our signature on them. Many people around the world recognize our buildings as symbols of modern Bahrain, and we hope to continue to create more iconic structures in the future.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 position-relative align-self-start order-lg-first order-last portfolio-item">
                            {/* <img src={require('../assets/projects/aboutus-page-1.jpg')} className="img-fluid" alt="">  */}
                        </div>
                    </div>

                </div>
            </section>
            <section id="project" className="project">
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-6 position-relative align-self-start order-lg-first order-first portfolio-item">
                            <img src={require('../assets/about/images/exceptionalLeaders.png')} className="img-fluid" alt="" />
                            {/* <div className="portfolio-info">
                      <h4 className="text-white">MINISTRY OF HEALTH</h4>
                      <p><i className="bi bi-pin-map-fill"></i> Manama, Bahrain</p>
                  </div> */}
                        </div>
                        <div className="col-lg-6 px-lg-5 content order-last order-lg-last d-flex justify-content-center align-items-center">
                            <div className="align-middle">
                                <div className="section-title">
                                    <h2 className="text-start">LED BY EXCEPTIONAL<br /><span className="u-text-light">LEADERS</span></h2>
                                </div>
                                <p>Our leadership team is made up of individuals who are passionate about engineering and committed to bringing a positive impact to the world. They work together with a core set of values to build an environment that fosters collaboration, learning, and growth. They guide our company, help us grow, and maintain our innovative spirit.</p>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
            <section id="commitment" className="commitment" >
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-6 content order-last order-lg-last d-flex justify-content-center align-items-center">
                            <div className="align-middle">
                                <div className="section-title">
                                    <h2 className="text-start">COMMITMENT TO <br /><span className="u-text-light">SUSTAINABILITY</span></h2>
                                </div>
                                <p className="mt-5">We believe that the best buildings and spaces are built on a foundation of efficiency, performance, and sustainability. Our team of architects, engineers, and designers work together in real time with our clients and contractors to create buildings that are beautiful, efficient, sustainable, comfortable—and affordable. Our goal is to create a lasting difference through every project we undertake—one that improves the quality of life for everyone who enters it.</p>
                            </div>
                        </div>

                    </div>

                </div>
            </section>

        </main>
    );
}

export default About;