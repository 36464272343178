const data = [
  {
    id: "19",
    image: [
     require("../assets/projects/images/project19/img0.png"),
     require("../assets/projects/images/project19/img1.png"),
    ],
    title: "ROSE GARDEN MASTERPLAN ",
    client: "ROYAL COURT OF BAHRAIN",
    area: "not provided",
    location: "BAHRAIN",
    content: `In AES, we lead the way in architectural master planning and urban design, crafting visionary spaces that inspire and enrich communities.Under the patronage of the Royal Court of Bahrain, we are privileged to present the Rose Garden Masterplan, an enchanting oasis designed to captivate the senses and celebrate nature's splendor.`,
    service: [
     "CAD and Drafting",
     "Architecture and Interior Design",
     "Infrastructure Planning and Management",
     "Master planning and Urban Design"
    ],
    industry: [
      "Infrastructure",
      "Government",
    ],
  },
  {
    id: "1",
    image: [
      require("../assets/projects/images/project1/img1.jpg"),
      require("../assets/projects/images/project1/img2.jpg"),
      require("../assets/projects/images/project1/img3.jpg"),
    ],
    title: "Hanging Garden, Dilmunia Island",
    client: "Cooperative Investment House",
    area: "60,128 m2",
    location: "Dilmunia Canal Frontage, Bahrain",
    service: [
      "Mechanical and Electrical Engineering",
      "Civil and Structural Engineering",
      "Architecture and Interior Design"
    ],
    industry: [
      "Residential"
    ],
    content: "The two ten story service accommodation and freehold apartments on Dilmunia island in Bahrain, is a feat of architecture and design that will leave you in awe. With 278 serviced five-star apartments, 196 freehold flats, 4100 square meters of retail space, and total underground parking for 420 + 242 automobiles, this double property fronting the Dilmunia Canal is an architectural landmark in Bahrain.\nFor this design and build project, Ansari Engineering Services was chosen to deliver the detailed design for the architectural, structural, and MEP services. The building's structures were planned with post tension slabs and 2.5-meter edge cantilevers, which were supported by curved RCC frames and shear walls with pile-bound perimeters."
  },
  {
    id: "2",
    image: [
      require("../assets/projects/images/project2/img1.png"),
      
    ],
    title: "National Motors Headquarters - Sitra",
    client: "National Motors Headquarters",
    area: "not provided",
    location: "Sitra, Bahrian",
    service: [
      "Project Management",
      "Mechanical and Electrical Engineering",
      "Civil and Structural Engineering",
      "Architecture and Interior Design",
      "Site Supervision"
    ],
    industry: [
      "Commercial",
    ],
    content: `AES provided Architectural and ID full site supervision for a 4-storey central office building that provides the HQ offices for NMC in Bahrain. The building has a curved & vaulted ceiling boardroom at penthouse level, as well as a customer reception centre at ground level.\n
    Three individual showrooms for Honda, General Motors and Cadillac, located in central headquarters of National Motors, Sitra. Two large workshops for GM & Honda are provided at the rear. The workshops are air-conditioned, and they can be viewed from large panoramic windows at ground level and the first floor customer service lounges and offices.
    `
  },
  {
    id: "3",
    image: [
      require("../assets/projects/images/project3/img1.jpg"),
      require("../assets/projects/images/project3/img2.jpg"),
      require("../assets/projects/images/project3/img3.jpg"),
    ],
    title: "Gravity Village",
    client: "Samhaan Holding Company",
    area: "93,721 m2",
    location: "Southern Governorate, near BIC",
    service: [
      "Project Management",
      "Mechanical and Electrical Engineering",
      "Civil and Structural Engineering",
      "Architecture and Interior Design",
      "Construction Management",
      "Site Supervision"
    ],
    industry: [
      "Commercial"
    ],
    content: `Located only 5 minutes drive from Bahraini University, Gravity Village is a young, dynamic cultural and sporting complex that includes the world's largest and most technologically sophisticated skydiving center. The project also includes an array of other facilities, including cinemas, sports venues, restaurants, and cafes.\n
    A central feature of this project is the "Halo," an ellipse-shaped recreational area that links all common sport phases facilities. AES are currently appointed to develop the detailed masterplan and all 4 phases of this project. We are working on schematic & detailed Architectural, Structural & MEP design on the initial new phases.
    `
  },
  {
    id: "4",
    image: [
      require("../assets/projects/images/project4/img1.JPG"),
      require("../assets/projects/images/project4/img2.jpg"),
      
    ],
    title: "Crown Plaza Car Parking and Retail Space",
    client: "Crown Plaza Hotel",
    area: "26,714 m2",
    location: "Diplomatic Area",
    content: `Ansari Engineering Services provided a customized design for this 9 storey car park which provides 595 car spaces, plus retail outlets. The project, which has been constructed using high quality materials and advanced construction techniques, is designed to meet the highest standards of safety and durability.\n
    Guests can access car park and retail outlets directly from the hotel entrance. Additionally, a a purpose-made side entrance provides close access to the Crowne Plaza's adjacent conference facilities. The car park is designed to provide maximum security for both vehicles and people.\n
    The car park itself can accommodate up to 959 cars, while 356 m2 of retail space and 525 m2 of restaurant space are also available onsite. 
    `,
    service: [
      "Project Management",
      "Mechanical and Electrical Engineering",
      "Civil and Structural Engineering",
      "Architecture and Interior Design",
      "Site Supervision"
    ],
    industry: [
      "Hospitality"
    ],
  },
  {
    id: "5",
   image: [
    require("../assets/projects/images/project5/img0.png"),
   ],
   title: "RECLAMATION FOR DILMUNIA ISLAND",
   client: "Ministry of Works",
   area: "not provided",
   location: "Dilmunia, Bahrain",
   content: `AES was selected by Special Projects (MOWMUP) to carry out the architectural and ID concept design and detailed architectural/ID/structural & MEP design for the prestigious Nawab Extension.\n
   The design calls for a four-story office building with a total area of 6057 m2 to house offices for the Speaker, Secretary General, and 32 MPs. The building is linked to a 2-story existing office facility and also contains a theater & V/VIP Royal Majlis.\n
   The architectural design is traditional Bahraini with a blend of modern and traditional for the interior design and FF & E. The concept design has been carried out using 3D modelling software and AutoCAD, while the detailed structural design was carried out using the STAAD Pro V8i structural analysis software package.
   `,
   service: [
    "Project Management",
    "CAD and Drafting",
    "Marine Engineering",
    "Site Supervision",
    "Quantity Surveying and Cost Engineering",
    "Cadastral and Land Surveying"
   ],
   industry: [
     "Government"
   ],
 },
  {
    id: "6",
    image: [
      require("../assets/projects/images/project6/img1.jpg"),
      require("../assets/projects/images/project6/img2.jpg"),
      require("../assets/projects/images/project6/img3.jpg"),
    ],
    title: "EWA New Administrative Building",
    client: "EWA",
    area: "Parking Provision – 538 Spaces",
    location: "Juffair, Bahrain",
    content: `The new headquarters for EWA in Juffair was designed, built and fitted out by AES. This 12-storey tower is clad in Jordanian sandstone with external LED lighting.\n
    The tower has a ground floor customer services and public exhibition hall complete with featured salt water shark tank and a 12-storey full height atrium with 4 panoramic lifts and 2 enclosed traditional lifts. Staff and customer areas are planned to discretely accommodate security requirements with ease of customer services.\n
    Completed in 2010, this tower was fully fitted out by AES. Individual EWA departments within the office tower have customised design layouts to accommodate their current use and future flexibility. The mezzanine and first floor levels accommodate both in house training and state of the art business conference facilities, with acoustically isolated conference rooms.
    
    `,
    service: [
      "Project Management",
      "Mechanical and Electrical Engineering",
      "Civil and Structural Engineering",
      "Architecture and Interior Design",
      "Site Supervision"
    ],
    industry: [
      "Government"
    ],
  },
  {
    id: "7",
    image: [
      require("../assets/projects/images/project7/img1.JPG"),
      require("../assets/projects/images/project7/img2.JPG"),
      require("../assets/projects/images/project7/img3.JPG"),
    ],
    title: "Junior School Complex",
    client: "Al Ruwad School (Now British School)",
    area: "not provided",
    location: "Bahrain Bay, Bahrain",
    content: `The new headquarters for EWA in Juffair was designed, built and fitted out by AES. This 12-storey tower is clad in Jordanian sandstone with external LED lighting.\n
    The tower has a ground floor customer services and public exhibition hall complete with featured salt water shark tank and a 12-storey full height atrium with 4 panoramic lifts and 2 enclosed traditional lifts. Staff and customer areas are planned to discretely accommodate security requirements with ease of customer services.\n
    Completed in 2010, this tower was fully fitted out by AES. Individual EWA departments within the office tower have customised design layouts to accommodate their current use and future flexibility. The mezzanine and first floor levels accommodate both in house training and state of the art business conference facilities, with acoustically isolated conference rooms.
    
    `,
    service: [
      "Project Management",
      "Mechanical and Electrical Engineering",
      "Civil and Structural Engineering",
      "Architecture and Interior Design",
      "Site Supervision"
    ],
    industry: [
      "Education"
    ],
  },
  {
    id: "8",
    image: [
      require("../assets/projects/images/project8/img1.JPG"),
      require("../assets/projects/images/project8/img2.JPG"),
      require("../assets/projects/images/project8/img3.JPG"),
    ],
    title: "Marketing Pavillion",
    client: "Bahrain Bay Development",
    area: "not provided",
    location: "Bahrain Bay, Bahrain",
    content: `The prime waterfront site at BBD was constructed in two phases, both of which were designed and supervised by AES. The first phase was a 620-square-meter 5-star marketing suite and studio for display branding and marketing of BBD facilities. The second phase involved an 860-square-meter two-story studio office building with a new first-floor executive wing fitted out with a model room for the new five star hotel room for Four Seasons.
    `,
    service: [
      "Project Management",
      "Mechanical and Electrical Engineering",
      "Civil and Structural Engineering",
      "Architecture and Interior Design",
      "Site Supervision"
    ],
    industry: [
      "Commercial"
    ],
  },
  {
    id: "9",
    image: [
      require("../assets/projects/images/project9/img1.JPG"),
      require("../assets/projects/images/project9/img2.JPG"),
      require("../assets/projects/images/project9/img3.JPG"),
    ],
    title: "Bahrain Bayan School",
    client: "Bahrain Bayan School",
    area: "not provided",
    location: "Isa Town, Bahrain",
    content: `Bahrain Bayan School is a world-class institution, and AES were the concept architects and engineers for all phases of its development. From its initial construction through almost 30 years of development, AES played an integral part in developing the school’s plans and ensuring that they were completed to the highest standards.\n
    The school has gone through six phases of development since 1985, when the primary school was built. Since then, AES has added the learning resource center (1985), the secondary school (1988), and the intermediate extension (1994). And in 2010, AES finished the new multipurpose hall and built the new car park. In 2019, AES opened the Laboratory and Classroom Extensions.
    
    `,
    service: [
      "Project Management",
      "Mechanical and Electrical Engineering",
      "Civil and Structural Engineering",
      "Architecture and Interior Design",
      "Site Supervision"
    ],
    industry: [
      "Education"
    ],
  },
  {
    id: "10",
    image: [
      require("../assets/projects/images/project10/img1.png"),
      require("../assets/projects/images/project10/img2.png"),
      
    ],
    title: "Car Showroom for Cadillac",
    client: "Cadillac",
    area: "not provided",
    location: "Sitra, Bahrain",
    content: `AES designed an incredible showroom for Cadillac. The showroom is an extension to the GMC showrooms at Sitra. Special attention was given by AES to incorporate Cadillac's branding details and specifications in the interior design. The Cadillac showroom was built with a stone façade and interior fit out. Customer parking is provided to the rear of the building. Plans have been completed for a future phase 2 extension. The space now looks as luxurious as Cadillac's vehicles.

    `,
    service: [
      "Project Management",
      "Mechanical and Electrical Engineering",
      "Civil and Structural Engineering",
      "Architecture and Interior Design",
      "Construction Management",
      "CAD and Drafting",
      "Site Supervision"
    ],
    industry: [
      "Commercial"
    ],
  },
  {
    id: "11",
    image: [
      require("../assets/projects/images/project11/img1.jpg"),
      require("../assets/projects/images/project11/img2.jpg"),
      
    ],
    title: "Ladies and Men’s Spa and Gymnasium and Pool Plaza Landscape",
    client: "Radisson Diplomatic Hotel",
    area: "not provided",
    location: "Diplomatic Area, Bahrain",
    content: `The Diplomat Spa and Gymnasium is a 5 star facility that is a major addition and upgrade to the existing 5 star Diplomat Hotel Conference Center & Spa. AES designed and supervised the hard and soft landscaping of the pool plaza area of the Diplomat to provide a central landscape focus for the spas and hotel.\n
    The ladies' spa and gymnasium facilities are located on the ground floor of the Diplomat Residence tower, and the men's spa and gymnasium are located in the landscaped plaza and pool area.
    
    `,
    service: [
      "Project Management",
      "Mechanical and Electrical Engineering",
      "Civil and Structural Engineering",
      "Architecture and Interior Design",
      "Site Supervision"
    ],
    industry: [
      "Hospitality"
    ],
  },
  {
    id: "12",
    image: [
      require("../assets/projects/images/project12/img1.jpg"),
      require("../assets/projects/images/project12/img2.jpg"),
      require("../assets/projects/images/project12/img3.JPG"),
    ],
    title: "Novotel Al Dana Hotel",
    client: "Novotel",
    area: "not provided",
    location: "Sheik Hamad Causeway, Bahrain",
    content: `The four star hotel is a 218 bedroom bay frontage hotel located on the Sheik Hamad Causeway. The building was designed and detailed in a traditional Bahraini style of architecture with wind tower features. The private room balconies have timber pergolas as well. The hotel facility also has three dining areas and a small conference facility. The hotel's location on the waterfront allows all main areas to have views of the sea bay and cityscape beyond. In addition to the hotel design work, AES was responsible for all necessary marine works including reclamation, beach design and offshore protection barrier.
    `,
    service: [
      "Project Management",
      "Mechanical and Electrical Engineering",
      "Civil and Structural Engineering",
      "Architecture and Interior Design",
      "Site Supervision"
    ],
    industry: [
      "Hospitality"
    ],
  },
  {
    id: "13",
    image: [
      require("../assets/projects/images/project13/img1.jpg"),
      require("../assets/projects/images/project13/img2.jpg"),
      
    ],
    title: "Land Reclamation/ Bahrain Fishing Harbour Project Development",
    client: "Ministry of Works & Fisheries Directorate",
    area: "not provided",
    location: "Budaiya, Bahrain",
    content: `AES was responsible for the Master Planning, Design, and Construction Supervision of the Fishing Harbour at Budaiya. The works included reclamation, construction of quay walls and associated building and infrastructure with an emphasis on Bahrain architecture and materials for the harbour quay cafeteria offices and harbour master’s look out tower.
    `,
    service: [
      "Project Management",
      "Mechanical and Electrical Engineering",
      "Quantity Surveying and Cost Engineering",
      "Cadastral and Land Surveying",
      "Marine Engineering",
      "Civil and Structural Engineering",
      "Architecture and Interior Design",
      "Site Supervision"
    ],
    industry: [
      "Government"
    ],
  },
   {
     id: "14",
     image: [
      require("../assets/projects/images/project14/img0.png"),
      require("../assets/projects/images/project14/img1.png"),
     ],
     title: "FISHING HARBOUR",
     client: "Ministry of Works & Fisheries Directorate",
     area: "not provided",
     location: "Sitra, BAHRAIN",
     content: `AES was responsible for the Master Planning, Design, and Construction Supervision of the Fishing Harbour at Sitra. The works included reclamation, construction of quay walls and associated building and infrastructure with an emphasis on Bahrain architecture and materials for the harbour quay cafeteria offices and harbour master’s look out tower.
     `,
     service: [
      "Project Management",
      "Mechanical and Electrical Engineering",
      "Cadastral and Land Surveying",
      "Quantity Surveying and Cost Engineering",
      "CAD and Drafting",
      "Site Supervision",
      "Marine Engineering"
     ],
     industry: [
       "Government"
     ],
   },
   {
    id: "15",
    image: [
     require("../assets/projects/images/project15/img0.png"),
     require("../assets/projects/images/project15/img1.png"),
    ],
    title: "GPR, TOPOGRAPHICAL & AS-BUILT SURVEY FOR TATWEER PETROLEUM",
    client: "Tatweer Petrolem",
    area: "not provided",
    location: "BAHRAIN",
    content: `AES was responsible for the Master Planning, Design, and Construction Supervision of the Tatweer Petroleum at Sitra. The works included reclamation, construction of quay walls and associated building and infrastructure with an emphasis on Bahrain architecture and materials for quay cafeteria offices and master’s look out petrol station.
    `,
    service: [
     "Project Management",
     "Cadastral and Land Surveying",
     "Quantity Surveying and Cost Engineering",
     "CAD and Drafting",
     "Site Supervision",
     "Infrastructure Planning and Management",
    ],
    industry: [
      "Industrial",
    ],
  },
  {
    id: "16",
    image: [
     require("../assets/projects/images/project16/img0.png"),
     require("../assets/projects/images/project16/img1.png"),
    ],
    title: "66 KV SUBSTATION",
    client: "BAHRAIN FINANCIAL HARBOUR",
    area: "not provided",
    location: "Manama, BAHRAIN",
    content: `AES was responsible for the Master Planning, Design, and Construction Supervision of the Power Substation at Manama. The works included reclamation, construction of quay walls and associated building and infrastructure with an emphasis on Bahrain architecture and materials for the substation.
    `,
    service: [
     "Project Management",
     "Mechanical and Electrical Engineering",
     "Construction Management",
     "CAD and Drafting",
     "Site Supervision",
     "Civil and Structural Engineering"
    ],
    industry: [
      "Infrastructure"
    ],
  },
  {
    id: "17",
    image: [
     require("../assets/projects/images/project17/img0.png"),
     require("../assets/projects/images/project17/img1.png"),
    ],
    title: "AL HIDD PHASE III DESALINATION PLANT",
    client: "HIDD POWER COMPANY BSC (CLOSED)",
    area: "not provided",
    location: "Manama, BAHRAIN",
    content: `AES led the master planning, design, and construction supervision of the Al Hidd Phase III Desalination Plant. Our focus on Bahraini architecture and materials ensured a seamless integration with the local landscape. Contact us to learn more about our innovative approach to sustainable infrastructure.`,
    service: [
     "Project Management",
     "Mechanical and Electrical Engineering",
     "CAD and Drafting",
     "Site Supervision",
     "Infrastructure Planning and Management",
     "Civil and Structural Engineering"
    ],
    industry: [
      "Industrial"
    ],
  },
  {
    id: "18",
    image: [
     require("../assets/projects/images/project18/img0.png"),
     require("../assets/projects/images/project18/img1.png"),
     require("../assets/projects/images/project18/img2.png"),
    ],
    title: "SOCIAL HOUSING FOR NASEEJ",
    client: "Misitry of Housing",
    area: "not provided",
    location: "BAHRAIN",
    content: `In our collaboration with the Ministry of Housing, AES spearheads the master planning, design, and construction supervision for the Naseej Social Housing project. Our dedicated focus on integrating Bahraini architecture and materials ensures a harmonious blend with the local environment`,
    service: [
     "Project Management",
     "CAD and Drafting",
     "Civil and Structural Engineering",
     "Architecture and Interior Design",
     "Cadastral and Land Surveying",
     "Infrastructure Planning and Management",
     "Master planning and Urban Design"
    ],
    industry: [
      "Infrastructure",
      "Government"
    ],
  },
  

]

export default data