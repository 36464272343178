import React from "react";
import banner1 from '../assets/projects/images/project3/img3.jpg';
import banner2 from '../assets/projects/images/project8/img3.JPG';
import banner3 from '../assets/projects/images/project14/img1.jpg';
import Carousel from 'react-bootstrap/Carousel';
import { useNavigate } from 'react-router-dom';
import svgLogo1 from '../assets/client-logo/1.png'
import svgLogo2 from '../assets/client-logo/2.png'
import svgLogo3 from '../assets/client-logo/3.png'
import svgLogo4 from '../assets/client-logo/4.png'
import svgLogo5 from '../assets/client-logo/5.png'
import svgLogo6 from '../assets/client-logo/6.png'
import svgLogo7 from '../assets/client-logo/7.png'
import svgLogo8 from '../assets/client-logo/8.png'


import expertiseData from '../data/expertiseData'
import { useEffect } from "react";

function Home() {
  const navigate = useNavigate()
  const navigateToExpertiseDetail = (id) => {
    console.log("id: ", id)
    navigate(`/expertise/${id}`)
  }
     

  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);

  return (
    <React.Fragment>
      <section id="hero">
        <Carousel fade>
          <Carousel.Item interval={2000}>
            <img className="d-block w-100" src={banner1} alt="First slide" />
            <div className="carousel-container">
              <div className="container">
                <h2 className="animate__animated animate__fadeInDown">ELEGANT . LUXURIOUS . UNCOMPROMISING</h2>
                {/*<p className="animate__animated animate__fadeInUp">25 Storey Residential Tower at Juffair for<Link to="#" className=""> Gulf Hotel Group </Link></p>*/}
                <br></br>
                <h2 class="entry-title">We are an ISO 9001:2015 Certified Company</h2>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item interval={2000}>
            <img className="d-block w-100" src={banner2} alt="Second slide" />
            <div className="carousel-container">
              <div className="container">
                <h2 className="animate__animated animate__fadeInDown">ELEGANT . LUXURIOUS . UNCOMPROMISING</h2>
                {/*<p className="animate__animated animate__fadeInUp">25 Storey Residential Tower at Juffair for<Link to="#" className=""> Gulf Hotel Group </Link></p>*/}
                <br></br>
                <h2 class="entry-title">We are an ISO 9001:2015 Certified Company</h2>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item interval={2000}>
            <img className="d-block w-100" src={banner3} alt="Third slide" />
            <div className="carousel-container">
              <div className="container">
                <h2 className="animate__animated animate__fadeInDown">ELEGANT . LUXURIOUS . UNCOMPROMISING</h2>
                {/*<p className="animate__animated animate__fadeInUp">25 Storey Residential Tower at Juffair for<Link to="#" className=""> Gulf Hotel Group </Link></p>*/}
                <br></br>
                <h2 class="entry-title">We are an ISO 9001:2015 Certified Company</h2>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </section>
      <main id="main">
        <section id="countdown" className="countdown">
          <div className="container">
            <div className="row justify-content-end">
              <div className="col-lg-11">
                <div className="row justify-content-end ">
                  <div className="col-lg-3 col-md-5 col-6 align-items-md-stretch">
                    <div className="count-box my-5">
                      <span data-purecounter-start="0" data-purecounter-end="45" className="purecounter" >45</span>
                      <p>Years of Existence</p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-5 col-6 align-items-md-stretch">
                    <div className="count-box my-5">
                      <span data-purecounter-start="0" data-purecounter-end="250" className="purecounter">250+</span>
                      <p>Delivered Projects</p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-5 col-6 align-items-md-stretch">
                    <div className="count-box my-5">
                      <span data-purecounter-start="0" data-purecounter-end="100" className="purecounter">100+</span>
                      <p>Workforce</p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-5 col-6 align-items-md-stretch">
                    <div className="count-box my-5 last-child">
                      <span data-purecounter-start="0" data-purecounter-end="20" className="purecounter">25+</span>
                      <p>Clients</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </section>

        <section id="project" className="project">
          <div className="container">
            <div className="section-title">
              <h2>PROJECT <span className="u-text-light">STORIES</span></h2>
              {/* <div className="section-title-divider"></div> */}
            </div>
            <div className="row content">
              <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                <img src={require('../assets/home/stories/1.png')} className="img-fluid" alt="" />
                <div className="portfolio-info">
                  <h4>Al Dana Hotel</h4>
                  <p>Novotel</p>
                  <span className="bilist bi bi-list"></span>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                <img src={require('../assets/home/stories/2.png')} className="img-fluid" alt="" />
                <div className="portfolio-info">
                  <h4>BAHRAIN BAY</h4>
                  <p>Bahrain Bay Development</p>
                  <span className="bilist bi bi-list"></span>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                <img src={require('../assets/home/stories/3.png')} className="img-fluid" alt="" />
                <div className="portfolio-info">
                  <h4>DILMUNIA ISLAND LAND RECLAMATION</h4>
                  <p>Ministry of Works</p>
                  <span className="bilist bi bi-list"></span>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                <img src={require('../assets/home/stories/4.png')} className="img-fluid" alt="" />
                <div className="portfolio-info">
                  <h4>EWA ADMINISTRATIVE BUILDING</h4>
                  <p>EWA</p>
                  <span className="bilist bi bi-list"></span>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                <img src={require('../assets/home/stories/5.png')} className="img-fluid" alt="" />
                <div className="portfolio-info">
                  <h4>HANGING GARDENS OF DILMUNIA</h4>
                  <p>Cooperative Investment House</p>
                  <span className="bilist bi bi-list"></span>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                <img src={require('../assets/home/stories/6.png')} className="img-fluid" alt="" />
                <div className="portfolio-info">
                  <h4>NAWAB EXTENSION</h4>
                  <p>Ministry of Health</p>
                  <span className="bilist bi bi-list"></span>
                </div>
              </div>
            </div>
            {/* <div className="text-center"><button className="load-more btn btn-warning" type="button"><span className="bi bi-caret-down-fill"></span>Load More</button></div> */}
          </div>
        </section>
        <section id="experties" className="experties">
          <div className="container">
            <div className="section-title">
              <h2>EXPERTISE</h2>
              {/* <div className="section-title-divider"></div> */}
            </div>
            <div className="row gy-5 content">
              {
                expertiseData.map(item => {
                  return (
                    <div className="col-lg-3 col-md-6 portfolio-item filter-app d-flex" onClick={() => navigateToExpertiseDetail(item.id)} style={{ cursor: "pointer" }}>
                      <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <img src={item.image} className="img-fluid" alt="" />
                        <div className="portfolio-info" style={{ height: "100%" }}>
                          <h3 style={{ textTransform: "uppercase" }}>{item.title}</h3>
                          <p>{item.content}</p>
                        </div>

                      </div>

                    </div>
                  )
                })
              }
              {/* <div className="col-lg-3 col-md-6 portfolio-item filter-app">
              <img src={require('../assets/projects/image1.jpg')} className="img-fluid" alt="" />
              <div className="portfolio-info">
                <h3>ARCHITECTURE</h3>
                <p>AE's Architectural expression embodies innovation, creativity and vision, reflecting each client disticive needs.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 portfolio-item filter-app">
              <img src={require('../assets/projects/image2.jpg')} className="img-fluid" alt="" />
              <div className="portfolio-info">
                <h3>CIVIL ENGINEERING</h3>
                <p>AE's Architectural expression  embodies innovation, creativity and vision, reflecting each client disticive needs.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 portfolio-item filter-app">
              <img src={require('../assets/projects/image3.jpg')} className="img-fluid" alt="" />
              <div className="portfolio-info">
                <h3>SURVEYING</h3>
                <p>AE's Architectural expression  embodies innovation, creativity and vision, reflecting each client disticive needs.</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 portfolio-item filter-app">
              <img src={require('../assets/projects/image4.jpg')} className="img-fluid" alt="" />
              <div className="portfolio-info">
                <h3>OTHERS</h3>
                <p>AE's Architectural expression  embodies innovation, creativity and vision, reflecting each client disticive needs.</p>
                
              </div>
            </div> */}
            </div>
          </div>
        </section>
        <section id="clients" className="clients" style={{ backgroundColor: "#FAF9F6" }}>
          <div className="container">

            <div className="row">



              <div className="col-lg-3 col-md-6 col-6 d-flex align-items-center justify-content-center">
                <img src={svgLogo1} className="img-fluid" alt="" />
              </div>
              <div className="col-lg-3 col-md-6 col-6 d-flex align-items-center justify-content-center">
                <img src={svgLogo2} className="img-fluid" alt="" />
              </div>
              <div className="col-lg-3 col-md-6 col-6 d-flex align-items-center justify-content-center">
                <img src={svgLogo3} className="img-fluid" alt="" />
              </div>

              <div className="col-lg-3 col-md-6 col-6 d-flex align-items-center justify-content-center">
                <img src={svgLogo4} className="img-fluid" alt="" />
              </div>
              <div className="col-lg-3 col-md-6 col-6 d-flex align-items-center justify-content-center">
                <img src={svgLogo5} className="img-fluid" alt="" />
              </div>
              <div className="col-lg-3 col-md-6 col-6 d-flex align-items-center justify-content-center">
                <img src={svgLogo6} className="img-fluid" alt="" />
              </div>
              <div className="col-lg-3 col-md-6 col-6 d-flex align-items-center justify-content-center">
                <img src={svgLogo7} className="img-fluid" alt="" />
              </div>
              <div className="col-lg-3 col-md-6 col-6 d-flex justify-content-center">
                <img src={svgLogo8} className="img-fluid" alt="" />
              </div>
            </div>

          </div>
        </section>
      </main>
    </React.Fragment>
  );
}

export default Home;