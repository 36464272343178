import React, { useEffect, useState } from "react";
import "../css/ExpertiseDetail.css";
import ExpertiseData from "../data/expertiseData";
import projectsData from "../data/projectsData";
import { useParams } from "react-router-dom";
import Slider from "react-slick";

const ExpertiseDetail = () => {
  const { id } = useParams();
  const [selectedExpertise, setSelectedExpertise] = useState("");
  const [projects, setProjects] = useState([]);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  useEffect(() => {
    const temp = ExpertiseData.find((item) => item.id === id);
    setSelectedExpertise(temp);
  }, [id]);

  useEffect(() => {
    if (selectedExpertise) {
      if (selectedExpertise.projects.length > 0) {
        const temp = projectsData.filter((item) =>
          selectedExpertise.projects.includes(item.id)
        );
        setProjects(temp);
      } else {
        setProjects([]);
      }
    }
  }, [selectedExpertise]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  console.log("projects ", projects);

  return (
    <>
      {selectedExpertise ? (
        <main
          className="expertiseDetail"
          style={{ backgroundColor: "#202020" }}
        >
          <div className="row gx-0 detailHeader">
            <div className="col-sm-12 col-lg-6 leftSide">
              <div>
                <h1
                  className="aboutus-breadcrumb"
                  style={{ margin: 0, padding: 0, textTransform: "uppercase" }}
                >
                  {selectedExpertise.title.split(" ")[0]}
                </h1>
                <h1
                  className="u-text-light"
                  style={{ textTransform: "uppercase" }}
                >
                  {selectedExpertise.title
                    .split(" ")
                    .slice(1)
                    .toString()
                    .replace(/,/g, " ")}
                </h1>
              </div>
            </div>
            <div
              className="col-sm-12 col-lg-6 rightSide"
              style={{ display: "flex", alignItems: "center" }}
            >
              <p>{selectedExpertise.content}</p>
            </div>
          </div>

          <div className=" breadcrumbs">
            <div
              className="page-header d-flex align-items-center"
              style={{
                backgroundImage: `url(${selectedExpertise.image})`,
              }}
            >
              <div className="container position-relative">
                <div className="row d-flex justify-content-center">
                  {/* <div className="col-lg-6 text-center ">
                      <h2 className="aboutus-breadcrumb">{selectedExpertise.title} <span className="u-text-light">EXPERTISE</span></h2>
                      <div className="section-title-divider aboutus"></div>
                    </div> */}
                </div>
              </div>
            </div>
          </div>

          <div className="row gx-0 contentContainer">
            <div className="col-sm-12">
              <h1 style={{ color: "white", marginBottom: "5vh" }}>
                Innovation happens at the intersections of our expertise.
              </h1>
              <p style={{ color: "white" }}>
                Our studios have no boundaries—each of our areas of expertise is
                deeply interwoven with the others. We’ve built a community based
                on sharing knowledge, because the best ideas flow between
                disciplines.
              </p>
            </div>
          </div>
          <div className="container">
            <div className="div-bottom-devider"></div>
          </div>

          {/* Cards */}
          <section id="project" className="project">
            <div className="container">
              {projects.length > 0 ? (
                projects.map((item, index) => {
                  let order = index % 2 === 0 ? "first" : "last";
                  return (
                    <div>
                      <div className="row gy-4">
                        <div className="col-lg-6 position-relative align-self-start order-lg-last order-first portfolio-item">
                          <Slider {...sliderSettings}>
                            {item.image.map((projImg) => (
                              <img
                                key={projImg}
                                src={projImg}
                                className="img-fluid"
                                alt=""
                              />
                            ))}
                          </Slider>

                          <div className="portfolio-info">
                            <h4 className="text-white">{item.title}</h4>
                            <p>
                              <i className="bi bi-pin-map-fill"></i>{" "}
                              {item.location}
                            </p>
                          </div>
                        </div>
                        <div
                          className={`col-lg-6 px-lg-5 content order-last order-lg-${order} d-flex justify-content-center align-items-center`}
                        >
                          <div className="align-middle">
                            <div className="section-title">
                              <h2 className="text-start">
                                {item.industry[0]}
                                <br />
                                <span className="u-text-light">
                                  {item.title}
                                </span>
                              </h2>
                            </div>
                            <p>Clien: {item.client}</p>
                            <p style={{ marginBottom: 0 }}>
                              Services Offered:{" "}
                            </p>
                            <ul>
                              {item.service.map((serviceItem) => {
                                return <li>{serviceItem}</li>;
                              })}
                            </ul>
                            <p>Gross Area: {item.area}</p>
                            <p>Location: {item.location}</p>
                            {item.content.split("\n").map((item) => {
                              return <p>{item}</p>;
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="div-bottom-devider"></div>
                    </div>
                  );
                })
              ) : (
                <h1 style={{ textAlign: "center" }}>
                  No Projects Found of this Expertise{" "}
                </h1>
              )}
            </div>
          </section>
        </main>
      ) : (
        <div className="breadcrumbs">
          <div
            className="page-header d-flex align-items-center"
            style={{ backgroundColor: "black" }}
          >
            <div className="container position-relative">
              <div className="row d-flex justify-content-center">
                <div className="col-lg-6 text-center ">
                  <h2 className="aboutus-breadcrumb">Loading ...</h2>
                  {/* <div className="section-title-divider aboutus"></div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ExpertiseDetail;
