import React from "react";

function News() {
  return (
    <main id="main" className="news">
        <div className="breadcrumbs">
            <div className="page-header d-flex align-items-center" style={{  backgroundImage: "url("+require('../assets/slider/expertise.jpg')+")"}} >
                <div className="container position-relative">
                    <div className="row d-flex justify-content-center">
                        <div className="col-lg-6 text-center ">
                            <h2 className="aboutus-breadcrumb">NEWS</h2>
                            <div className="section-title-divider news"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section id="project" className="project">
            <div className="container mt-5">
                <div className="row gy-4"> 
                    <div className="col-lg-6 position-relative align-self-start order-lg-last order-first portfolio-item">
                        <img src={require('../assets/projects/aboutus-page-1.jpg')} className="img-fluid" alt="" />
                        
                    </div>
                    <div className="col-lg-6 px-lg-5 content order-last order-lg-first d-flex justify-content-center align-items-center">
                        <div className="align-middle">
                            <div className="section-title">
                                <h2 className="text-start"><span className="u-text-light">INNOVATION HAPPENS AT THE INTERSECTIONS OF OUR EXPERTISE.</span></h2>
                                <span className="text-white">1 JANUARY 2022 | MANAMA, BAHRAIN</span>
                            </div>
                            <p>Meet our management team. They guide our company, helping us live our purpose and achieve our vision to lead the future of engineering. The leadership team works together with a core set of values to drive culture and purpose in our organization.</p>
                        </div>
                    </div>
                </div>
                <div className="div-bottom-devider"></div>
                <div className="row gy-4"> 
                    <div className="col-lg-6 position-relative align-self-start order-lg-first order-first portfolio-item">
                        <img src={require('../assets/projects/aboutus-page-1.jpg')} className="img-fluid" alt="" />
                        
                    </div>
                    <div className="col-lg-6 px-lg-5 content order-last order-lg-last d-flex justify-content-center align-items-center">
                        <div className="align-middle">
                            <div className="section-title">
                                <h2 className="text-start"><span className="u-text-light">INNOVATION HAPPENS AT THE INTERSECTIONS OF OUR EXPERTISE.</span></h2>
                                <span className="text-white">1 JANUARY 2022 | MANAMA, BAHRAIN</span>
                            </div>
                            <p>Meet our management team. They guide our company, helping us live our purpose and achieve our vision to lead the future of engineering. The leadership team works together with a core set of values to drive culture and purpose in our organization.</p>
                        </div>    
                    </div>
                    
                </div>
                <div className="div-bottom-devider"></div>
                <div className="row gy-4"> 
                    <div className="col-lg-6 position-relative align-self-start order-lg-last order-first portfolio-item">
                        <img src={require('../assets/projects/aboutus-page-1.jpg')} className="img-fluid" alt="" />
                        
                    </div>
                    <div className="col-lg-6 px-lg-5 content order-last order-lg-first d-flex justify-content-center align-items-center">
                        <div className="align-middle">
                            <div className="section-title">
                                <h2 className="text-start"><span className="u-text-light">INNOVATION HAPPENS AT THE INTERSECTIONS OF OUR EXPERTISE.</span></h2>
                                <span className="text-white">1 JANUARY 2022 | MANAMA, BAHRAIN</span>
                            </div>
                            <p>Meet our management team. They guide our company, helping us live our purpose and achieve our vision to lead the future of engineering. The leadership team works together with a core set of values to drive culture and purpose in our organization.</p>
                        </div>
                    </div>
                </div>
                <div className="div-bottom-devider"></div>
                <div className="row gy-4"> 
                    <div className="col-lg-6 position-relative align-self-start order-lg-first order-first portfolio-item">
                        <img src={require('../assets/projects/aboutus-page-1.jpg')} className="img-fluid" alt="" />    
                    </div>
                    <div className="col-lg-6 px-lg-5 content order-last order-lg-last d-flex justify-content-center align-items-center">
                        <div className="align-middle">
                            <div className="section-title">
                                <h2 className="text-start"><span className="u-text-light">INNOVATION HAPPENS AT THE INTERSECTIONS OF OUR EXPERTISE.</span></h2>
                                <span className="text-white">1 JANUARY 2022 | MANAMA, BAHRAIN</span>
                            </div>
                            <p>Meet our management team. They guide our company, helping us live our purpose and achieve our vision to lead the future of engineering. The leadership team works together with a core set of values to drive culture and purpose in our organization.</p>
                        </div>
                    </div>
                
                </div>
            </div>
        </section>
    </main>
  );
}

export default News;