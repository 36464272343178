import React, { useEffect, useState } from "react";
import projectsData from '../data/projectsData'
import expertiseData from '../data/expertiseData'
import Slider from 'react-slick';


function Projects() {

    const [projects, setProjects] = useState(projectsData)
    const [industryOptions, setIndustryOptions] = useState([ // eslint-disable-line no-unused-vars
        "Commercial",
        "Industrial",
        "Education",
        "Hospitality",
        "Infrastructure",
        "Residential",
        "Government"
    ]) // eslint-disable-line no-unused-vars
    const [service, setService] = useState("all")
    const [industry, setIndustry] = useState("all")
    const [showMore, setShowMore] = useState({
        flag: false,
        id: "0"
    })

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000
    };

    const handleServiceChange = (e) => {
        setService(e.target.value)
        // setProject("all")
    }

    useEffect(() => {
        let temp = projectsData
        if (service !== "all") {
            temp = temp.filter(item => item.service.includes(service))
        }

        if (industry !== "all") {
            temp = temp.filter(item => item.industry.includes(industry))

        }
        setProjects(temp)

    }, [service, industry])

    

    // useEffect(() => {
    //     const temp = projectsData.map(item => (item.industry[0]))
    //     const uniqueIndArray = [...new Set(temp)]
    //     setIndustryOptions(uniqueIndArray)

    // }, [])

    const handleShowMore = (id) => {
        if (showMore.flag) {
            if (showMore.id === id) {
                setShowMore({ flag: false, id: "" })
            } else {
                setShowMore({ flag: true, id })
            }
        } else {
            setShowMore({ flag: true, id: id })
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <main id="main" className="project">
            <div className="breadcrumbs">
                <div className="page-header d-flex align-items-center" style={{ backgroundImage: "linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 100%, rgba(0, 0, 0, 1) 100%), url(" + require('../assets/projects/images/project12/img3.JPG') + ")" }}>
                    <div className="container position-relative">
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-6 text-center ">
                                <h2 className="aboutus-breadcrumb">PROJECTS <span className="u-text-light">STORIES</span></h2>
                                {/* <div className="section-title-divider aboutus"></div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section id="breadth" className="breadth lightblackbg">
                <div className="container">
                    <div className="section-title">
                        <p className="text-center">BREADTH OF EXPERTISE, DEPTH OF UNDERSTANDING <br />WE CAN BUILD WHATEVER YOU IMAGINE.</p>
                    </div>
                </div>
            </section>
            <section id="project" className="project">
                <div className="container">
                    <div className="section-title filter">
                        <div className="row service-filter">
                            {/* <div className="filter-text col-md-1 col-sm-12">Filter</div> */}
                            <div className="select col-md-3 col-sm-6">
                                <label className="control-label">Service</label>
                                <select className="form-control col-sm-6" value={service} onChange={handleServiceChange}>
                                    <option value="all">All</option>
                                    {
                                        expertiseData.map(item => {

                                            return (
                                                <option value={item.title}>{item.title}</option>

                                            )

                                        })
                                    }
                                </select>
                            </div>
                          {/*  <div className="select col-md-3">
                                <label className="control-label">Industries</label>
                                <select className="form-control" value={industry} onChange={(e) => setIndustry(e.target.value)} >
                                    <option value="all">All</option>
                                    {
                                        industryOptions.length > 0 && industryOptions.map(item => {
                                            return (
                                                <option value={item}>{item}</option>

                                            )
                                        })
                                    }
                                     <option value="wall interior">Wall interior</option>
                                    <option value="bedroom interior" >Bedroom interior</option>
                                    <option value="bedroom interior" >Bedroom interior</option> 

                                </select>
                            </div> */}
                        </div>
                    </div>
                    {
                        projects.length > 0 ? projects.map((item, index) => {
                            let order = index % 2 === 0 ? "first" : "last"
                            return (
                                <div>
                                    <div className="row gy-4">
                                        <div className="col-lg-6 position-relative align-self-start order-lg-last order-first portfolio-item">
                                            <Slider {...sliderSettings}>
                                                {
                                                    item.image.map(projImg => {
                                                        return (
                                                            <img src={projImg} className="img-fluid" alt="" />

                                                        )
                                                    })
                                                }
                                            </Slider>
                                            <div className="portfolio-info">
                                                <h4 className="text-white">{item.title}</h4>
                                                <p><i className="bi bi-pin-map-fill"></i>{item.location}</p>
                                            </div>
                                        </div>
                                        <div className={`col-lg-6 px-lg-5 content order-last order-lg-${order} d-flex justify-content-start align-items-center`} >
                                            <div className="align-middle">
                                                <div className="section-title" style={{ paddingBottom: "20px" }}>
                                                    <h2 className="text-start"><span className="u-text-light ">{item.title}</span></h2>
                                                </div>
                                                <p>Client: {item.client}</p>
                                                <p style={{ marginBottom: 0 }}>Services Offered: </p>
                                                <ul>
                                                    {
                                                        item.service.map(serviceItem => {
                                                            return (
                                                                <li>{serviceItem}</li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                                {
                                                    showMore.flag && showMore.id === item.id && (
                                                        <div>
                                                            {item.area !== "not provided" && (
                                                                <p>Gross Area: {item.area}</p>

                                                            )}
                                                            <p>Location: {item.location}</p>
                                                            {item.content.split("\n").map(item => {
                                                                return (
                                                                    <p>{item}</p>
                                                                )
                                                            })}

                                                        </div>
                                                    )
                                                }
                                                <div >
                                                    <button className="load-more btn btn-warning" onClick={() => handleShowMore(item.id)} type="button"><span className={showMore.flag && showMore.id === item.id ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"}></span>{showMore.flag && showMore.id === item.id ? "Read Less" : "Read More"}</button>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                    <div className="div-bottom-devider"></div>
                                </div>

                            )
                        }) : (
                            <h1 style={{ textAlign: "center" }} >No Related Data Found</h1>
                        )
                    }


                </div>
            </section>
        </main>
    );
}

export default Projects;